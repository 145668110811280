/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
    background: #1a1a1b;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

body * {
    font-family: 'Montserrat', sans-serif !important;
}

.highcharts-tooltip-box {
    stroke-width: 1px;
    fill: #2B2734;
    fill-opacity: 0.85;
    background: #2B2734;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
}

.ant-dropdown .ant-dropdown-menu {
    background: #2F2D34 !important;
}

.ant-dropdown-menu .ant-dropdown-menu-title-content{
    color: #fff !important;
    opacity: .5;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    color: #fff !important;
    opacity: 1;
    background: none;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected .ant-dropdown-menu-title-content {
    opacity: 1;
}

.ant-dropdown.ant-dropdown-show-arrow .ant-dropdown-arrow:before,
.ant-dropdown.ant-dropdown-show-arrow .ant-dropdown-arrow:after,
.ant-dropdown-placement-bottom .ant-dropdown-arrow:before, 
.ant-dropdown-placement-bottom .ant-dropdown-arrow:after {
    background: #2F2D34;
}
