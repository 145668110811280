.icon {
    display: flex !important;
    justify-content: center;
    
    // svg path {
    //     fill: currentColor;
    // }

    &.fillColor {
        svg {
            path {
                fill: currentColor;
            }
        }
    }

    &.size16 {
        svg {
            scale: calc(16/16);
        }
    }

    &.size20 {
        svg {
            scale: calc(20/16);
        }
    }

    &.size24 {
        svg {
            scale: calc(24/16);
        }
    }

    &.size28 {
        svg {
            scale: calc(28/16);
        }
    }

    &.size32 {
        svg {
            scale: calc(32/16);
        }
    }

    &.clickable {
        cursor: pointer;
    }

}
