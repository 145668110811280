.container {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-top: 0;
    border-bottom: 0;
    min-width: 310px;

    .arrow {
        transition: all 0.2s;

        &.arrowOpen {
            transform: rotate(180deg);
        }
    }

    &:hover {
        color: #4096ff;

        svg path {
            fill: currentColor;
        }
    }
}

.menu {
    :global {
        :not(.ant-dropdown-menu-item-selected) {
            .ant-dropdown-menu-item-icon svg path {
                fill-opacity: 0.3;
            }
        }
    }
}