.container {
    color: white;
    font-size: 16px;
}

.menu {
    color: white;

    .menuItem {
        &:hover {
            background-color: rgba(0, 0, 0, 0.2) !important;
        }
    }

    // :global(.ant-dropdown-menu-item-selected .ant-dropdown-menu-item-icon svg path) {
    //     fill-opacity: 1;
    // }
}