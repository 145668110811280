.container {

  padding: 0 16px;

  .icon {

    &.opened {
      color: #F6425E;
    }

    &:hover {
      color: #F6425E;
    }

  }
  
}

.overlay {

  > div {
    margin-right: 12px;
  }

  .profileMenu {
    min-width: 220px;
    margin-right: 8px !important;
  
    .header {
      background-color: #2A292E;
      border-top-left-radius: inherit;
      border-top-right-radius: inherit;
      margin-bottom: 4px;
      border-bottom: 1px solid rgba(23, 23, 23, 0.40);
  
      .headerLabel {
        color: white;
        display: flex;
        align-items: center;
        gap: 8px;
      }
  
      &:hover {
        background-color: #2A292E !important;
      }
    }
  
    .version {
      cursor: default !important;
      margin-top: 4px !important;
      border-top: 1px solid rgba(23, 23, 23, 0.40);
  
      &:hover {
        background-color: initial !important;
      }
    }
  
  }

}
